<template>
  <div class="contact_us">
    <div class="nav-inner">
      <div class="nav-tab">
        <div class="tab">
          <div class="hotel-logo" @click="goHome()">
            <span class="logo-img" />
            <span>NovoCrédito</span>
          </div>
        </div>
      </div>
    </div>
    <div class="con_center">
      <terminos-item v-if=" type === '1'" />
      <laft-item v-if=" type === '2'" />
      <contrato-item v-if=" type === '3'" />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import TerminosItem from '@/components/contactUs/TerminosItem'
import LayoutBottom from '@/components/LayoutBottom'
import LaftItem from '@/components/contactUs/LaftItem.vue'
import ContratoItem from '../../components/contactUs/contratoItem.vue'
export default {
  components: {
    LayoutBottom,
    TerminosItem,
    LaftItem,
    ContratoItem
  },
  data() {
    return {
    }
  },
  created() {
    this.type = this.$route.query.type
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px){
  // .con_center{
    // padding-top: 88px;
  // }
}
@media only screen and (max-width: 767px){}

.nav-inner {
  width: calc(100vw);
  padding:0px calc((100% - 1100px) / 2) 0;
  background: #fff;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 99999;
  .nav-tab {
    height: 70px;
    align-items: center;
    margin: 0 auto !important;
    // padding: 10px 0;
    .hotel-logo {
      float: left;
      height: 70px;
      line-height: 70px;
      span:nth-child(1) {
        display: inline-block;
        width: 160px;
        height: 75px;
        vertical-align:middle;
        background: url("~@/assets/images/loanImg/logo_2.png") no-repeat center;
        background-size: contain;
      }
      span:nth-child(2){
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        vertical-align:middle;
        margin-left: 10px;
        color: #333333
      }
    }
    .hotel-li {
      float: right;
      height: 100%;
      li {
        display: inline-block;
        position: relative;
        // width: 16.66%;
        padding: 0 20px;
        height: 100%;
        font-size: 16px;
        text-align: center;
        line-height: 70px;
        white-space:nowrap;
        cursor: pointer;
        a{
          color: #999999;
          text-decoration: none;
        }
        &.under-line{
          color: $color-286BD9;
          a{
            color: #286BD9;
          }
          &::after{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0px;
            content: '';
            width: 90px;
            height: 3px;
            background: $color-286BD9;
          }
        }
      }
    }
  }
}
</style>
